package common

import common.AddinCardType.AddinCardType
import common.AddinSectionType.AddinSectionType
import common.CalendarAccessRole.CalendarAccessRole
import common.ServiceType.{ServiceType, ServiceTypeDetails}
import io.circe.{Decoder, Encoder}
import shapeless.CNil

import java.time.Instant
import scala.language.implicitConversions
import scala.util.Try

case class AurinkoAccount(
                           id: Int,
                           serviceType: ServiceType,
                           email: Option[String],
                           name: Option[String],
                           userAccountType: String,
                           clientOrgId: Option[String],
                           authOrgId: Option[String],
                           authUserId: Option[String],
                           serverUrl: Option[String],
                           authObtainedAt: Instant,
                           loginString: Option[String],
                           tokenStatus: TokenStatus.TokenStatus
                         )

object TokenStatus extends Enumeration with JsonEnum {
  case class Val(value: String, ok: Boolean = true) extends super.Val(value)

  type TokenStatus = Val
  type EnumValue = Val

  val dead: TokenStatus = Val("dead", ok = false)
  val invalid: TokenStatus = Val("invalid", ok = false)
  val required: TokenStatus = Val("required")
  val active: TokenStatus = Val("active")


}

case class AurinkoUser(id: String,
                       appId: Long,
                       name: Option[String]
                      )

case class AurinkoApiPage[T](
                              //offset: Int,
                              length: Int,
                              //done: Boolean,
                              records: List[T]
                            )


object ServiceType extends Enumeration with JsonEnum {
  case class Val(value: String, label: String, isDefault: Boolean = false) extends super.Val(value)

  type ServiceType = Val
  type EnumValue = Val

  val google: ServiceType = Val("Google", "Google", isDefault = true)
  val office365: ServiceType = Val("Office365", "Office 365", isDefault = true)
  val ews: ServiceType = Val("EWS", "MS Exchange", isDefault = true)
  val ews365: ServiceType = Val("EWS365", "Office 365 (EWS)", isDefault = true)
  val imap: ServiceType = Val("IMAP", "IMAP", isDefault = true)
  val slack: ServiceType = Val("Slack", "Slack")
  val webex: ServiceType = Val("Webex", "Webex", isDefault = true)
  val zoom: ServiceType = Val("Zoom", "Zoom", isDefault = true)
  val teamwork: ServiceType = Val("Teamwork", "Teamwork", isDefault = true)

  val salesforce: ServiceType = Val("Salesforce", "Salesforce", isDefault = true)
  val repfabric: ServiceType = Val("Repfabric", "Repfabric", isDefault = true )
  val sugarCRM: ServiceType = Val("SugarCRM", "Sugar CRM", isDefault = true)
  val hubspot: ServiceType = Val("Hubspot", "Hubspot", isDefault = true)
  val autoQuotes: ServiceType = Val("AutoQuotes", "AutoQuotes", isDefault = true)
  val eclipseERP: ServiceType = Val("EclipseERP", "Eclipse ERP", isDefault = true)
  val netSuite: ServiceType = Val("NetSuite", "NetSuite", isDefault = true)
  val highLevel: ServiceType = Val("HighLevel", "HighLevel", isDefault = true)

  val msTeamsBot: ServiceType = Val("MsTeamsBot", "MS Teams Bot")
  val googleBot: ServiceType = Val("GoogleBot", "Google Bot")
  val webexBot: ServiceType = Val("WebexBot", "Webex Bot")
  val slackBot: ServiceType = Val("SlackBot", "Slack Bot")
  val zoomBot: ServiceType = Val("ZoomBot", "Zoom Bot")

  val unknown: ServiceType = Val("unknown", "unknown")

  val list: List[ServiceType] =
    google ::
      office365 ::
      ews ::
      ews365 ::
      imap ::
      repfabric ::
      salesforce ::
      sugarCRM ::
      hubspot ::
      autoQuotes ::
      eclipseERP ::
      netSuite ::
      highLevel ::
      slack ::
      webex ::
      zoom ::
      slackBot ::
      msTeamsBot ::
      googleBot ::
      webexBot ::
      zoomBot ::
      teamwork ::
      Nil

  private val crmTypes: List[ServiceType] =
    repfabric ::
    salesforce ::
    sugarCRM ::
    hubspot ::
    autoQuotes ::
    eclipseERP ::
    netSuite ::
    highLevel ::
      Nil

  def fromString(someVal: String): ServiceType = list.find(_.value == someVal).getOrElse(throw new Exception(s"Unknown service type: $someVal"))

  implicit final class ServiceTypeDetails(private val serviceType: ServiceType) extends AnyVal {
    def isCRM: Boolean = crmTypes.contains(serviceType)
  }
}

case class TabAccounts(
                        primaryAccount: AurinkoAccount,
                        secondaryAccount: AurinkoAccount
                      )

case class PagedApiResponse[T](
                                pageToken: Option[String],
                                records: List[T]
                              )

case class Calendar(
                     id: String,
                     name: String,
                     color: String,
                     accessRole: CalendarAccessRole,
                     primary: Boolean
                   )


case class StorageMapping(
                           value: Option[String]
                         )

case class AddinPage(
                      header: Option[String],
                      sections: List[AddinSection],
                      sectionsLoadPath: Option[String],
                      //check if needed option
                      showMenu: Boolean,
                      headerCard: Option[AddinCard]
                      )

case class AddinSection(
                         `type`: AddinSectionType,
                         header: Option[String],
                         cards: List[AddinCard],
                         filterSubmitData: List[String],
                         loadPath: Option[String]
                          )

object AddinSectionType extends Enumeration with JsonEnum {

  type AddinSectionType = Value
  type EnumValue = Value

  val cardList,
  loggedInfo,
  logEntityForm,
  filter,
  cardSelection,
  details,
  editDetails,
  menu,
  actions,
  crmObjects,
  info,
  settings = Value
}

case class AddinCard(`type`: AddinCardType,
                     crmObjectType: Option[String],
                     crmObjectId: Option[String],
                     filterPath: Option[String],
                     fieldValue: Option[String],
                     fieldName: Option[String],
                     filterSubmitData: List[String],
                     filterNavMode: Option[String],
                     selectionOptions: List[String],
                     lineText: List[String],
                     lineTopLabel: List[String],
                     lineBottomLabel: List[String],
                     crmObjectSubtype: Option[String],
                     crmObjectRtId: Option[String],
                     crmObjectLink: Option[String]
                    ) {
  val disableNavigation: Boolean = filterNavMode.contains("none")
}

object AddinCardType extends Enumeration with JsonEnum {

  type AddinCardType = Value
  type EnumValue = Value

  val crm,
    entityContact,
    email,
    picklist,
    multipicklist,
    text,
    phone,
    currency,
    string,
    integer,
    num, // ??
    menuItem,
    date,
    datetime,
    time,
    boolean,
    userInfo,
    navigation,
    radio,
    delete,
    addinAction
      = Value
}


case class GraphApiResponse[T](
                                value: T
                              )

case class TokenData(
                      consumer: TokenDataConsumer,
                      clientData: List[String]
                    )


object TokenData {
  def apply(encodedChannelData: String, tenantId: String, personalOfficeAccId: Option[String] = None): TokenData = TokenData(
    consumer = TokenDataConsumer(orgId = tenantId),
    clientData = (Some(encodedChannelData) :: personalOfficeAccId :: Nil).flatten
  )
}

case class TokenDataConsumer(
                              serviceType: ServiceType = ServiceType.msTeamsBot,
                              orgId: String
                            )

case class RepfabricUser(
                          id: Int,
                          name: Option[String],
                          `client-id`: Int,
                          email: String,
                          `client-name`: String,
                          `admin-user`: Boolean,
                          owner: Boolean
                        ) {
  val isAdmin: Boolean = `admin-user` || owner
}

trait LabeledValue {
  val label: String
  val value: String
}

case class RepfabricManufacturer(
                                  id: Int,
                                  name: String,
                                  visible: Boolean
                                ) {
  override def toString: String = name
}

case class RfApiPage[T](
                         `next-page-url`: String,
                         records: List[T]
                       ) {
  val nextPageId: Option[String] = `next-page-url`.split("\\?").last.split("&").find(_.contains("next-page-id")) match {
    case Some(str) if str.split("=").last != "-1" => Some(str.split("=").last)
    case _ => None
  }

}


case class MsTeam(
                   id: String
                 )

case class MsChannel(
                      id: String
                    )

object CalendarAccessRole extends Enumeration with JsonEnum {
  type CalendarAccessRole = Value
  type EnumValue = Value

  val owner, writer, reader = Value
}

trait JsonEnum {
  self: Enumeration =>

  protected type EnumValue <: self.Value

  implicit def valueToEnumValue(x: Value): EnumValue = x.asInstanceOf[EnumValue]

  implicit def encoder: Encoder[EnumValue] = Encoder[String].contramap(_.toString)

  implicit def decoder: Decoder[EnumValue] = Decoder[String].emapTry(str => Try(withName(str)))
}


